export const REST_URLS = {
    POST: {
        LOGIN: '/login',
        SET_OTP_PASSWORD: '/users/set_otp_password',
        USERS: '/users',
        LEADS: '/leads',
        USER_INFO: '/users/user_info',
        RESEND_OTP: '/users/resend_otp',
        MESSAGE: '/messages',
        CART: '/store/addToCart',
        APPOINTMENT: '/request_appointment',
        ADTPost: '/postAdtOutput',
        APPLYUPGRADEPROGRAM: '/apply_for_upgrade_program',
        GERMANY: {
            ADTPost: '/germany_postAdtOutput'
        },
        BOM_CALCULATOR: '/website/en-us/residential'
    },
    GET: {
        SE_CONFIG: '/se_configs',
        SEARCH_LEADS: '/leads/lead_info',
        DRUPAL_MICRO_LINK: '/leads/drupalForm',
        PLATFORM_STATUS: '/users/platform_status',
        INSTALLERS: '/installers/matching2',
        SITE_INFO: '/users/sites_info',
        SITE_ACCESS_INFO: '/users/all_accessible_sites',
        BOM_INFO: '/bom/getBOMList',
        CART: '/store/addToCart',
        QUOTE_DOWNLOAD_LINK: '/quotes/presignedurl',
        QUOTE_UPLOAD_IMAGES: '/leads/presignedurl',
        APPLIANCES: '/appliances',
        ROOF_SEGMENT_STATS: '/getpanel_placement',
        SUMMARY_HTML: '/leads/summaryHTML',
        IMPERSONATING_USER_INFO: '/users/impersonating_user_info',
        INSTALLER_LOGO_INFO: '/installer_logo_info',
        MESSAGES_HISTORY: '/message_history',
        APPOINTMENT_HISTORY: '/appointment_history',
        SAT_PLANS: '/accTariffs',
        SAT_CONSUMPTION: '/bill2Usage',
        SAT_GENABILITY: '/genPreprocess',
        SAT_SAVINGS: '/get_savings_for_mode',
        SAT_SAVINGS_CUSTOM: '/get_custom_savings_for_all_modes',
        SAT_SAVINGS_FOR_SAVINGS_MODE: '/get_savings_for_all_modes',
        SAT_TARIFF_DESC: '/tariffDesc',
        SAT_UPDATE_STORAGE_BILLS: '/storageSav',
        CONTRACT_DOCUMENT: '/documents/presignedurl',
        GET_PRICES: '/installers/get_prices',
        SG_QUOTE: '/enlm/financials',
        VERIFY_PHONE: '/api/validate',
        GERMANY: {
            SAT_ACCOUNTID: '/germany_account',
            SAT_PREPROCESS: '/germany_preprocess',
            GET_SAVINGS: '/germany_getSavings',
            OPTIMIZE_SAVINGS: '/germany_optimiseSavings'
        },
        BOM_IMAGE: '/bom'

    },
    PUT: {
        LEADS: '/leads',
        USERS: '/users',
        UPDATE_INSTALLER_STATE: '/installers/update_installer_state',
        UPDATE_INSTALLER_LEAD_STATE: '/installers/lead_state',
        ACCEPT_APPOINTMENT: '/accept_appointment',
        REJECT_APPOINTMENT: '/reject_appointment',
        CANCEL_APPOINTMENT: '/cancel_appointment'
    }
};
